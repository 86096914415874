<template>
    <!-- eslint-disable -->
    <Form 
        :action="action" 
        :id="id" 
        :kelompokVal="kelompok" 
        :penggunaVal="pengguna" 
        :sediaanVal="sediaan" 
        :detailSediaanVal="detailSediaan" 

        :dataPenggunaVal="dataPengguna" 
        :dataSediaanVal="dataSediaan" 
    />
</template>

<script>
import Form from './Form.vue'
import {ref} from '@vue/composition-api'

export default {
    setup(props, context){
        const g = context.root

        const $http = g.$http

        var kelompok = ref('')
        var pengguna = ref({})
        var sediaan = ref('')
        var detailSediaan = ref([])

        var dataPengguna = ref([])
        var dataSediaan = ref([])

        const getData = () => {
            $http({
                url: '/mst/tagihan/biaya/ubah/'+props.id,
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    const {kelompokVal, penggunaVal, sediaanVal, detailSediaanVal, dataBiaya} = res.data.data
                    const {dataPenggunaVal, dataSediaanVal} = dataBiaya
                    kelompok.value = kelompokVal
                    pengguna.value = penggunaVal
                    sediaan.value = sediaanVal
                    detailSediaan.value = detailSediaanVal

                    dataPengguna.value = dataPenggunaVal
                    dataSediaan.value = dataSediaanVal
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Load Data`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        getData()

        return {
            kelompok, pengguna, sediaan, detailSediaan,
            dataPengguna, dataSediaan
        }
    },
    data(){
        return{
            action: 'Ubah'
        }
    },
    components: {
        Form
    },
    props: ['id']
}
</script>