<template>
    <div>
        <b-card no-body>
            <b-card-header>
                <b-card-title>{{action}} Data</b-card-title>
                <b-card-title class="text-primary">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="secondary"
                        class="btn-icon"
                        size="sm"
                        :to="{name: 'mstTagihanBiaya'}"
                    >
                        <feather-icon icon="ArrowLeftIcon" />
                    </b-button>
                </b-card-title>
            </b-card-header>

            <b-card-body>
                <b-form class="needs-validation" @submit.prevent="submitForm">
                    <b-form-row>
                        <b-col md="10" offset-md="1" class="pl-0 pr-0">
                            <!-- input kelompok -->
                            <b-form-group
                                label="Kelompok"
                                label-for="kelompok"
                            >
                                <b-form-select
                                    v-model="v$.kelompok.$model"
                                    :options="dataKelompok"
                                    :state="validateState(v$.kelompok)"
                                    @change="changeKelompok"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.kelompok.$errors" :key="index">
                                    {{ error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <!-- input pengguna -->
                            <b-form-group
                                :label="labelPengguna"
                                label-for="pengguna"
                                v-if="kelompok == 'RS / Klinik' || kelompok == 'Dokter Pemeriksa' || kelompok == 'Dokter Pengirim'"
                            >
                                <v-select
                                    v-model="v$.pengguna.$model"
                                    label="text"
                                    :options="dataPengguna"
                                    @input="inputPengguna"
                                />
                                <small class="text-danger" v-if="kelompok == 'RS / Klinik' && v$.pengguna.$errors.length > 0">
                                    RS / Klinik tidak boleh kosong
                                </small>
                                <small class="text-danger" v-if="kelompok == 'Dokter Pemeriksa' && v$.pengguna.$errors.length > 0">
                                    Dokter Pemeriksa tidak boleh kosong
                                </small>
                                <small class="text-danger" v-if="kelompok == 'Dokter Pengirim' && v$.pengguna.$errors.length > 0">
                                    Dokter Pengirim tidak boleh kosong
                                </small>
                            </b-form-group>
                            
                            <!-- input sediaan -->
                            <b-form-group
                                label="Sediaan"
                                label-for="sediaan"
                            >
                                <b-form-select
                                    v-model="v$.sediaan.$model"
                                    :options="dataSediaan"
                                    :state="validateState(v$.sediaan)"
                                    @change="changeSediaan"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.sediaan.$errors" :key="index">
                                    {{ error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <div v-if="detailSediaan.length > 0">
                                <b-row >
                                    <b-col cols="2" offset-md="10">
                                        <!-- input semuaBiaya -->
                                        <b-form-group
                                            label="Set Semua Biaya"
                                            label-for="semuaBiaya"
                                        >
                                            <b-form-input
                                                id="semuaBiaya"
                                                @keyup="keyupSemuaBiaya"
                                                @keydown="changeIntOnly"
                                                v-model="semuaBiaya"
                                                @input="inputSemuaBiaya"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row v-for="(item, index) of v$.detailSediaan.$model" :key="index">
                                    <b-col
                                        cols="10"
                                    >
                                        <!-- input Detail Sediaan -->
                                        <b-form-group
                                            label="Detail Sediaan"
                                            label-for="detailSediaan"
                                        >
                                            <b-form-input
                                                v-model="item.text"
                                                disabled
                                            />
                                            <b-form-invalid-feedback v-for="(error, index2) of v$.detailSediaan.$each.$response.$errors[index].id" :key="index2">
                                                {{ error.$message }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        cols="2"
                                    >
                                        <!-- input biaya -->
                                        <b-form-group
                                            label="Biaya"
                                            label-for="biaya"
                                        >
                                            <b-form-input
                                                @keyup="keyupBiaya(index)"
                                                @keydown="changeIntOnly"
                                                v-model="item.biaya"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </div>

                            <!-- tombol -->
                            <b-col
                                cols="12"
                                class="text-right pr-0"
                            >
                                <b-button
                                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                    type="button"
                                    variant="outline-secondary"
                                    class="mr-1"
                                    @click="resetForm"
                                >
                                    Reset
                                </b-button>
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="submit"
                                    variant="primary"
                                >
                                <div v-if="isSubmit">
                                    <b-spinner small />
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div v-else>Simpan</div>
                                </b-button>
                            </b-col>
                        </b-col>
                    </b-form-row>
                </b-form>
            </b-card-body>
        </b-card>
    </div>
  
</template>

<script>
import {
    BCard, BForm, BFormRow, BCol, BFormInput, BFormValidFeedback, BFormInvalidFeedback, BCardHeader, BCardBody, BButton, BFormGroup, BCardTitle, BSpinner, BFormSelect, BRow
} from 'bootstrap-vue'
import { required, helpers} from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import {ref, computed, watchEffect } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
    components: {
        vSelect,
        BCard, BForm, BFormRow, BCol, BFormInput, BFormValidFeedback, BFormInvalidFeedback, BCardHeader, BCardBody, BButton, BFormGroup, BCardTitle, BSpinner, BFormSelect, BRow
    },
    props: {
        kelompokVal: String,
        penggunaVal: Object,
        sediaanVal: String,
        detailSediaanVal: Array,

        dataPenggunaVal: Array,
        dataSediaanVal: Array,
        action: String,
        id: String,
    },
    setup(props, context){
        const g = context.root

        var isSubmit = ref(false)
        const router = g.$router
        const $http = g.$http

        //form name
        var kelompok = ref(null)
        var pengguna = ref({text: '-- Pilih Opsi --', value: null})
        var sediaan = ref(null)
        var detailSediaan = ref([])
        var semuaBiaya = ref('')

        var labelPengguna = ref('')

        var dataSediaan = ref([])
        var dataKelompok = ref([
            {text: '-- Pilih Opsi --', value: null},
            {text: 'RS / Klinik', value: 'RS / Klinik'},
            {text: 'Dokter Pemeriksa', value: 'Dokter Pemeriksa'},
            {text: 'Dokter Pengirim', value: 'Dokter Pengirim'},
            {text: 'Default', value: 'Default'}
        ])
        var dataPengguna = ref([])

        watchEffect(() => {
            kelompok.value = props.kelompokVal
            pengguna.value = props.penggunaVal
            labelPengguna.value = props.kelompokVal
            sediaan.value = props.sediaanVal
            detailSediaan.value = props.detailSediaanVal

            dataSediaan.value = props.dataSediaanVal
            dataPengguna.value = props.dataPenggunaVal
        })

        const checkRequiredPengguna = (val) => {
            if((typeof val.value === 'undefined' || val.value == '' || val.value == null) && kelompok.value != 'Default')
                return false
            
            return true
        }

        // validation
        const requiredMsg = 'tidak boleh kosong'
        const $externalResults = ref({})
        const rules = computed(() => ({
            kelompok: {
                required: helpers.withMessage('Kelompok '+requiredMsg, required)
            },
            pengguna: {
                checkRequiredPengguna
            },
            sediaan: {
                required: helpers.withMessage('Sediaan '+requiredMsg, required)
            },
            detailSediaan: {
                $each: helpers.forEach({
                    id: {
                        required: helpers.withMessage('Detail Sediaan '+requiredMsg, required)
                    }
                })
            }
        }))

        const v$ = useVuelidate(rules, { detailSediaan, kelompok, pengguna, sediaan }, {$externalResults, $autoDirty: true})

        const submitForm = async () => {
            // isSubmit.value = true
            const isFormCorrect = await v$.value.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            //jika error validasi
            if (!isFormCorrect) {
                isSubmit.value = false
                return
            }

            // mapping data
            let data = {
                kelompok: kelompok.value,
                pengguna: pengguna.value.value,
                detailSediaan: detailSediaan.value,
                id: props.id
            };

            $http({
                url: '/mst/tagihan/biaya/submit/'+props.action,
                data,
                method: 'post',
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    router.push({name: 'mstTagihanBiaya'})
                })
                .catch(err => {
                    isSubmit.value = false
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Submit Form`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const changeSediaan = () => {
            if(!sediaan.value)
                detailSediaan.value = []
            else{
                $http({
                    url: '/pemeriksaan/sediaan/get-sediaan-detail',
                    params: {
                        sediaan: sediaan.value,
                    },
                    headers: {
                        Authorization: 'Bearer '+g.$cookies.get('token')
                    }
                })
                    .then(res => {
                        const {detailSediaanVal} = res.data.data
                        detailSediaan.value = detailSediaanVal
                    })
                    .catch(err => {
                        let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                        g.$bvToast.toast(showError || 'Something Wrong', {
                            title: `Error Load Table`,
                            variant: 'danger',
                            solid: true,
                        })
                    })
            }
        }

        const changeKelompok = () => {
            if(kelompok.value == 'RS / Klinik'){
                $http({
                    url: '/mst/rsKlinik/get-data',
                    params: {
                        row: -1
                    },
                    headers: {
                        Authorization: 'Bearer '+g.$cookies.get('token')
                    }
                })
                    .then(res => {
                        dataPengguna.value = res.data.data
                        dataPengguna.value.unshift({text: '-- Pilih Opsi --', value: null})
                    })
                    .catch(err => {
                        let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                        g.$bvToast.toast(showError || 'Something Wrong', {
                            title: `Error Submit Form`,
                            variant: 'danger',
                            solid: true,
                        })
                    })
            }
            else if(kelompok.value == 'Dokter Pemeriksa'){
                $http({
                    url: '/mjmn/pengguna/get-dokter/dokter-pemeriksa',
                    headers: {
                        Authorization: 'Bearer '+g.$cookies.get('token')
                    }
                })
                    .then(res => {
                        dataPengguna.value = res.data.data.dataDokterVal
                    })
                    .catch(err => {
                        let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                        g.$bvToast.toast(showError || 'Something Wrong', {
                            title: `Error Submit Form`,
                            variant: 'danger',
                            solid: true,
                        })
                    })
            }
            else if(kelompok.value == 'Dokter Pengirim'){
                $http({
                    url: '/mjmn/pengguna/get-dokter/dokter-pengirim',
                    headers: {
                        Authorization: 'Bearer '+g.$cookies.get('token')
                    }
                })
                    .then(res => {
                        dataPengguna.value = res.data.data.dataDokterVal
                    })
                    .catch(err => {
                        let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                        g.$bvToast.toast(showError || 'Something Wrong', {
                            title: `Error Submit Form`,
                            variant: 'danger',
                            solid: true,
                        })
                    })
            }
            else if(kelompok.value == 'Default')
                inputPengguna()
            
            pengguna.value = {text: '-- Pilih Opsi --', value: null}
            labelPengguna.value = kelompok.value
            sediaan.value = null
            dataSediaan.value = []
            detailSediaan.value = []
        }

        const inputPengguna = () => {
            let penggunaVal = pengguna.value ? pengguna.value.value : null
            $http({
                url: '/mst/tagihan/biaya/get-sediaan',
                params: { 
                    pengguna: penggunaVal,
                    kelompok: kelompok.value
                },
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    detailSediaan.value = []
                    dataSediaan.value = res.data.data.dataSediaan
                    sediaan.value = null
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Submit Form`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const inputSemuaBiaya = () => {
            for (let i = 0; i < detailSediaan.value.length; i++) {
                detailSediaan.value[i].biaya = intThousand(semuaBiaya.value)
            }
        }

        const keyupSemuaBiaya = () => {
            semuaBiaya.value = intThousand(semuaBiaya.value)
        }

        const keyupBiaya = index => {
            detailSediaan.value[index].biaya = intThousand(detailSediaan.value[index].biaya)
        }

        const intThousand = value => {
            let hasil = value

            if(hasil != '' && typeof hasil !== 'undefined'){
                hasil = hasil.toString().replace(/\./g, '')
                hasil = hasil.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }

            return hasil
        }

        const changeIntOnly = (evt) => {
            var ASCIICode = (evt.which) ? evt.which : evt.keyCode
            if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
                evt.preventDefault()
        }

        const validateState = (item) => {
            const { $dirty, $error } = item
            return !$dirty ? null : $error ? false : null
        }

        const validateState2 = (item) => {
            const { $error } = item
            return $error ? false : null
        }

        const resetForm = () => {
            nama.value = ''
            emailName.value = ''
            peran.value = null
            password.value = ''
            konfirmasiPassword.value = ''
            username.value = ''
        }

        return {
            v$, $externalResults,
            detailSediaan, kelompok, pengguna, labelPengguna, semuaBiaya, sediaan,
            dataKelompok, dataPengguna, dataSediaan,
            isSubmit,
            resetForm, validateState, submitForm, changeSediaan, validateState2, changeKelompok, inputPengguna, changeIntOnly, keyupBiaya, keyupSemuaBiaya, inputSemuaBiaya
        }
    },
    directives: {
        Ripple,
    },
}
</script>

<style lang="scss" scope>
@import '@core/scss/vue/libs/vue-select.scss';
</style>